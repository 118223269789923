import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height fill-width d-flex flex-column",attrs:{"fluid":""}},[_c(VRow,{staticClass:"d-flex flex-row",staticStyle:{"width":"100vw"}},[_c(VCol,[_c(VList,[_c(VListItemGroup,[_c(VDivider),_c('div',[_c(VListItem,{on:{"click":function($event){_vm.selectedReport = 'working_times'}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('Report Orario')}}),_c(VListItemSubtitle,{staticClass:"text--primary",domProps:{"textContent":_vm._s('Esportazione Ore Lavorate')}})],1),_c(VListItemIcon,{attrs:{"align":"center"}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1),_c(VDivider),_c('div',[_c(VListItem,{on:{"click":function($event){_vm.selectedReport = 'delay_times'}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('Report Ritardi')}}),_c(VListItemSubtitle,{staticClass:"text--primary",domProps:{"textContent":_vm._s('Analisi dei Ritardi in Ingresso')}})],1),_c(VListItemIcon,{attrs:{"align":"center"}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1),_c(VDivider)],1)],1)],1),_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedReport),expression:"selectedReport"}],attrs:{"inset":"","vertical":""}}),_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedReport),expression:"selectedReport"}],attrs:{"cols":"9"}},[(_vm.selectedReport === 'working_times')?_c('WorkTimeReportGenerator',{attrs:{"exportableConsolidationRecords":_vm.exportableConsolidationRecords},on:{"working-hours-report-export":_vm.workingHoursReportExport,"working-hours-report-generate":_vm.workingHoursReportGenerate}}):_vm._e(),(_vm.selectedReport === 'delay_times')?_c('span',[_vm._v(" Coming soon ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }