<template>
    <v-container fluid class="fill-height fill-width d-flex flex-column">
        <v-row class="d-flex flex-row" style="width: 100vw;">
            <v-col>   
                <v-list>
                    <v-list-item-group>
                        <v-divider></v-divider>
                        <div>
                            <v-list-item
                                @click="selectedReport = 'working_times'"
                            >
                                <v-list-item-content>
                                <v-list-item-title v-text="'Report Orario'"></v-list-item-title>
                                <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="'Esportazione Ore Lavorate'"
                                ></v-list-item-subtitle>

                                <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-icon  align="center">
                                <v-icon>mdi-chevron-right</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </div>
                        <v-divider></v-divider>
                        <div>
                            <v-list-item
                                @click="selectedReport = 'delay_times'"
                            >
                                <v-list-item-content>
                                <v-list-item-title v-text="'Report Ritardi'"></v-list-item-title>
                                <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="'Analisi dei Ritardi in Ingresso'"
                                ></v-list-item-subtitle>

                                <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
                                </v-list-item-content>
                                <v-list-item-icon  align="center">
                                <v-icon>mdi-chevron-right</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </div>
                        <v-divider></v-divider>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-divider
                inset
                vertical
                v-show="selectedReport"
            ></v-divider>
            <v-col v-show="selectedReport" cols="9">
                <WorkTimeReportGenerator 
                    v-if="selectedReport === 'working_times'"
                    :exportableConsolidationRecords="exportableConsolidationRecords"
                    v-on:working-hours-report-export="workingHoursReportExport"
                    v-on:working-hours-report-generate="workingHoursReportGenerate"
                />
                <span v-if="selectedReport === 'delay_times'">
                    Coming soon
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ConsolidationRecordsApi  from '../../api/consolidationRecord'
import ReportApi from '../../api/report'

export const WorkTimeReportGenerator = () => import('./reportGenerators/WorkTimesReportGenerator.vue')

import { mapActions } from 'vuex'

import * as snackbarActions from '../../store/actions/snackbar'

export default ({
    data: function() {
        return {
            selectedReport: null,
            exportableConsolidationRecords: [],
        }
    },
    methods: {
        ...mapActions({
            showSnackbar: snackbarActions.SNACKBAR_SHOW
        }),
        workingHoursReportExport: function(companyName, month, year) {
            ReportApi.exportWorkingHoursReport(companyName, month, year).then(response => {
                if(response.success) {
                    this.showSnackbar({text: "REPORT INVIATO CON SUCCESSO", type: "SUCCESS"});
                } else {
                    this.showSnackbar({text: "ESPORTAZIONE DEL REPORT FALLITA", type: "ERROR"});
                }
            });
        },
        workingHoursReportGenerate: function(companyName, month, year) {
            ReportApi.generateWorkingHoursReport(companyName, month, year).then(response => {
                if(response.success) {
                    this.showSnackbar({text: "REPORT GENERATO CON SUCCESSO", type: "SUCCESS"});
                    this.fetchExportableConsolidationRecords();
                } else {
                    this.showSnackbar({text: "GENERAZIONE DEL REPORT FALLITA", type: "ERROR"});
                }
            });
        },
        fetchExportableConsolidationRecords: function() {
            ConsolidationRecordsApi.getExportableConsolidationRecords().then(response => {
                if(response.success) {
                    this.exportableConsolidationRecords = response.data;
                } else {
                    this.showSnackbar({text: "ERRORE NEL CARICAMENTO DEI PERIODI CONSOLIDATI", type: "ERROR"});
                }
            });
        }
    },
    computed: {
    },
    components: {
        WorkTimeReportGenerator
    },
    mounted: function() {
        this.fetchExportableConsolidationRecords();
    }
})
</script>